import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FileNode } from "gatsby-plugin-image/dist/src/components/hooks"
import { MDXRenderer } from "gatsby-plugin-mdx"
import * as React from "react"
import Layout from "../../components/layout"
import {
  date as dateStyle,
  postBody,
  postTitle,
  postTitleAndBody,
} from "./blog.module.css"

export const pageQuery = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        date(formatString: "MMMM YYYY")
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

interface BlogPageProps {
  data: {
    mdx: {
      frontmatter: {
        title: string
        date: string
        hero_image?: FileNode
        hero_image_alt?: string
        hero_image_credit_text?: string
        hero_image_credit_link?: string
      }
      body: string
    }
  }
}

const BlogPost = (props: BlogPageProps) => {
  const {
    title,
    date,
    hero_image: heroImage,
    hero_image_alt: heroImageAlt,
    hero_image_credit_text: heroImageCredit,
    hero_image_credit_link: heroImageCreditLink,
  } = props.data.mdx.frontmatter

  const image = heroImage ? getImage(heroImage) : null

  return (
    <Layout pageTitle={title}>
      <div className={postTitleAndBody}>
        <h2 className={postTitle}>{title}</h2>
        <p className={dateStyle}>{date}</p>
        {image && heroImageAlt && (
          <div>
            <GatsbyImage image={image} alt={heroImageAlt} />
            <p>
              Photo Credit: <a href={heroImageCreditLink}>{heroImageCredit}</a>
            </p>
          </div>
        )}
        <div className={postBody}>
          <article>
            <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
          </article>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPost
